<template>
  <div id="root">

    <div style="display: flex;">
      <el-card style="flex: 1;margin-right: 20px;">
        <div slot="header" class="clearfix">
          <span>昨日统计</span>
        </div>
        <div v-for="(item,index) in yesterday" style="text-align: left;" :style="{marginTop:(index==0?0:20)+'px'}">
          <el-row :gutter="20" type="flex" align="middle">
            <el-col class="label" :span="5">{{ item.label }}</el-col>
            <el-col :span="19" class="value">{{ item.value }}</el-col>
          </el-row>
        </div>
      </el-card>
      <el-card style="flex: 1">
        <div slot="header" class="clearfix">
          <span>今日统计</span>
        </div>
        <div v-for="(item,index) in today" style="text-align: left;" :style="{marginTop:(index==0?0:20)+'px'}">
          <el-row :gutter="20" type="flex" align="middle">
            <el-col class="label" :span="5">{{ item.label }}</el-col>
            <el-col :span="19" class="value">{{ item.value }}</el-col>
          </el-row>
        </div>
      </el-card>
    </div>


    <div ref="barchart" style="width: 100%;flex: 1;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import {BarChart} from 'echarts/charts'
import {CanvasRenderer} from "echarts/renderers";
import {LegendComponent, GridComponent, TitleComponent} from "echarts/components";

export default {
  name: "Qrcode",
  data() {
    return {
      bc:null,
      today: [],
      yesterday: [],
      counts:[]
    }
  },
  mounted() {
    echarts.use([
      BarChart, CanvasRenderer, LegendComponent, GridComponent, TitleComponent
    ])
    this.bc = echarts.init(this.$refs.barchart);
    this.fillData();
    this.initBarChart()
  },
  methods: {
    async fillData() {
      let data = await this.$http.get("/statics/data");
      let table = [{key: "ipCount", label: "IP数量"}, {key: "over5sCount", label: "登录5秒以上"}, {
        key: "less5sCount",
        label: "登录5秒以下"
      }, {key: "ipFilterCount", label: "同IP过滤"}];
      this.today = [];
      this.yesterday = []
      for (let d of table) {
        this.today.push({
          label: d.label,
          value: data.today[d.key]
        })
        this.yesterday.push({
          label: d.label,
          value: data.yesterday[d.key]
        })
      }
      this.counts=data.counts;
      this.$forceUpdate()
      this.initBarChart();
    },
    initBarChart() {
      this.bc.setOption({
        xAxis: {
          name: "统计日期",
          type: "category",
          axisTick: {
            interval: 0,
            color: 'white'
          },
          axisLabel: {
            interval: 0,
            color: 'black',
            formatter: (val) => {
              return this.$util.formatDate(val+"")
            }
          },
          nameTextStyle: {
            color: "#066390"
          },
          data: this.counts.map(item=>item.timeStamp),
        },
        title: {
          text: "最近7天用户统计",
          left: 'center',
          top: 50
        },
        grid: {
          left: '20%',
          right: '20%',
          top: 100,
          bottom: 150,
          containLabel: true
        },
        yAxis: {
          name: "新用户数（同IP去重）",
          nameTextStyle: {
            color: "#066390"
          },
          bottom: 200,
          minInterval: 1,
          axisLabel: {
            interval: 0,
            formatter: (val) => {
              return val + '人'
            }
          }
        },

        series: [
          {
            type: "bar",
            data: this.counts.map(item=>item.count),
            color:"rgb(140,164,238)",
            label: {
              show: true,
              color: '#fac448',
              verticalAlign: "center",
              align: 'center',
              formatter: d => {
                return `${d.value} 人`
              },
              rich: {
                a: {
                  width: 'auto',
                  align: 'left'
                },
              }
            }
          },
        ]
      });
      window.onresize=()=>{
        bc.resize()
      }
    }
  }
}
</script>

<style scoped>
#root {
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.label {
  color: #666;
}
</style>